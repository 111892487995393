import { Card, Spin } from "antd";
import gql from "graphql-tag";
import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "../../hooks";
import { EditGeneral } from "./EditGeneral";
import { EditOptions } from "./EditOptions";
import { EditTarget } from "./EditTarget";
import { EditServers } from "./EditServers";
import * as g from "./__generated__/PlanName";

const GET_PLAN_NAME = gql`
  query PlanName($id: ID!) {
    plan(id: $id) {
      id
      name
    }
  }
`;

const gridStyleFull = {
  width: "100%",
  outline: "none",
};

const gridStyleHalf = {
  width: "50%",
  outline: "none",
};

export const PlanEdit = () => {
  const { id } = useParams<{ id: string }>();

  const { loading, data } = useQuery<g.PlanName, g.PlanNameVariables>(GET_PLAN_NAME, {
    variables: { id },
  });

  if (loading) {
    return <Spin />;
  }

  return (
    <Card
      title={data?.plan.name}
      bordered={false}
      headStyle={{ padding: "0" }}
      bodyStyle={{ padding: "24px 0" }}
    >
      <Card.Grid style={gridStyleHalf} hoverable={false}>
        <EditGeneral />
      </Card.Grid>
      <Card.Grid style={gridStyleHalf} hoverable={false}>
        <EditTarget />
        <br />
        <EditServers />
      </Card.Grid>
      <Card.Grid style={gridStyleFull} hoverable={false}>
        <EditOptions />
      </Card.Grid>
    </Card>
  );
};
