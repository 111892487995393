import { CopyOutlined } from "@ant-design/icons";
import { Button, Card, Col, notification, Row, Space, Spin, Statistic, Tag } from "antd";
import gql from "graphql-tag";
import moment from "moment";
import React, { Fragment, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link, useParams } from "react-router-dom";
import { useMutation, useQuery } from "../../hooks";
import { LogListControlled } from "../Log";
import { Traffic } from "../Traffic";
import { PortPlan, PortSettings } from "./";
import * as g from "./__generated__/PortInfo";
import * as gr from "./__generated__/ResetPortEdit";
import * as gf from "./__generated__/VPNConfigEdit";
import { downloadConfig } from "./DownloadConfig";

const GET_PORT_INFO = gql`
  query PortInfo($id: ID!) {
    port(id: $id) {
      id
      user {
        id
        login
      }
      plan {
        enabled
        expiresAt
        tarification {
          time
        }
      }
      servers {
        apServerId
        apServerIp
        apServerHost
        apServerCountryCode
        vpnServerId
      }
      vpnAccess
      resetToken
      ip
      status
      currentModem
    }
  }
`;

const PORT_TRAFFIC = gql`
  query PortTraffic($id: ID!, $from: Time!, $to: Time!, $kind: String!) {
    traffic(id: $id, from: $from, to: $to, kind: $kind) {
      rx
      tx
      chart {
        ts
        kind
        value
      }
    }
  }
`;

const RESET_PORT = gql`
  mutation ResetPortEdit($id: ID!) {
    resetPort(id: $id) {
      id
      status
    }
  }
`;

const VPN_CONFIG = gql`
  query VPNConfigEdit($port: String!, $server: String!) {
    vpnConfig(port: $port, server: $server)
  }
`;


const tabList = [
  {
    key: "overview",
    tab: "Overview",
  },
  {
    key: "plan",
    tab: "Plan",
  },
  {
    key: "settings",
    tab: "Settings",
  },
  {
    key: "log",
    tab: "Log",
  }
];

export const PortEdit = () => {
  const { id } = useParams<{ id: string }>();

  const [selectedTab, selectTab] = useState("overview");

  const { loading, data } = useQuery<g.PortInfo, g.PortInfoVariables>(GET_PORT_INFO, {
    variables: { id }
  });

  const { "refetch": fetchConfig } = useQuery<gf.VPNConfigEdit, gf.VPNConfigEditVariables>(VPN_CONFIG, {
    skip: true,
    fetchPolicy: "no-cache",
  });

  const [resetPort] = useMutation<gr.ResetPortEdit, gr.ResetPortEditVariables>(RESET_PORT, {
    okText: "Reset scheduled",
    refetchQueries: ["PortInfo"],
  });

  if (loading) {
    return <Spin />;
  }

  const title = (
    <Fragment>
      <Link to={`/users/${data?.port.user.id}`}>{data?.port.user.login}</Link> / {data?.port.id}
    </Fragment>
  );

  return (
    <Fragment>
      <Card
        title={title}
        bordered={false}
        headStyle={{ padding: "0" }}
        bodyStyle={{ padding: "24px 0" }}
        tabList={tabList}
        activeTabKey={selectedTab}
        onTabChange={(tabKey) => selectTab(tabKey)}
      >
        {selectedTab === "overview" && <Fragment>
          <Card>
            <h3>Info</h3><br />
            <Row gutter={24}>
              <Col span={8}>
                <Statistic title="Status" value={data?.port.status} />
              </Col>
              <Col span={8}>
                <Statistic title="IP" value={data?.port.ip} />
              </Col>
              <Col span={8}>
                <Statistic title="Current Modem" valueStyle={{ display: "none" }} />
                {data?.port.currentModem === "empty" ? <Tag>{data?.port.currentModem}</Tag> : <Link to={`/modems/${data?.port.currentModem}`}><Tag color="blue">{data?.port.currentModem}</Tag></Link>}
              </Col>
            </Row>
            <br />
            <Row gutter={24}>
              <Col span={8}>
                <Statistic 
                  title="AP Server" 
                  value={data?.port.servers.apServerId 
                    ? `${data.port.servers.apServerHost}`
                    : "Default"
                  }
                  valueStyle={{ fontSize: "14px" }}
                />
              </Col>
              <Col span={8}>
                <Statistic 
                  title="VPN Server" 
                  value={data?.port.servers.vpnServerId || "Default"}
                  valueStyle={{ fontSize: "14px" }}
                />
              </Col>
            </Row>
            <br />
            <Row gutter={24}>
              <Col span={8}>
                <Statistic title="Actions" valueStyle={{ display: "none" }} />
                <Space>
                  <Button
                    type="primary"
                    onClick={() => resetPort({ variables: { id } })}>
                    Reset IP
                  </Button>
                  <CopyToClipboard
                    text={`https://api.ltesocks.io/v2/port/reset/${data?.port.resetToken}`}
                    onCopy={() => notification.info({ message: "Link copied" })}
                  >
                    <Button
                      icon={<CopyOutlined />}
                    >
                      Reset Link
                    </Button>
                  </CopyToClipboard>
                  {data?.port.vpnAccess && <Button
                    onClick={() => fetchConfig({ port: id, server: "vpn1.ltesocks.io" })
                      .then(z => downloadConfig(id, z.data.vpnConfig))
                      .catch(e => notification.error({ message: "Error", description: e.message }))
                    }
                  >
                    VPN Config
                  </Button>}
                </Space>
              </Col>
            </Row>
          </Card>
          <br />
          <Traffic id={data!.port.id} kind="port" initialFrom={data!.port.plan.enabled ? moment(data!.port.plan.expiresAt).subtract(data!.port.plan.tarification.time, "seconds").toDate() : undefined} query={PORT_TRAFFIC} />

        </Fragment>}
        {selectedTab === "plan" && <PortPlan />}
        {selectedTab === "settings" && <PortSettings />}
        {selectedTab === "log" && data?.port && <LogListControlled subjects={[data!.port.id]} />}
      </Card>
    </Fragment >
  );
};
