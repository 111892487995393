/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum LogAction {
  assign = "assign",
  changeSignature = "changeSignature",
  changeTarget = "changeTarget",
  create = "create",
  delete = "delete",
  loginFailed = "loginFailed",
  loginSuccess = "loginSuccess",
  refreshToken = "refreshToken",
  release = "release",
  reset = "reset",
  update = "update",
  updateAutoRenew = "updateAutoRenew",
  updateAutoReset = "updateAutoReset",
  updateCredentials = "updateCredentials",
  updatePassword = "updatePassword",
  updatePlan = "updatePlan",
  updatePool = "updatePool",
  updateStatus = "updateStatus",
  updateTags = "updateTags",
}

export enum LogFacility {
  admin = "admin",
  banner = "banner",
  modem = "modem",
  node = "node",
  pool = "pool",
  port = "port",
  promo = "promo",
  referrerToken = "referrerToken",
  router = "router",
  signature = "signature",
  user = "user",
  vpnPort = "vpnPort",
  vpnServer = "vpnServer",
}

export enum LogSource {
  adminDashboard = "adminDashboard",
  system = "system",
  userApi = "userApi",
  userDashboard = "userDashboard",
}

export enum ModemManufacturer {
  android = "android",
  huawei = "huawei",
  huawei525 = "huawei525",
  huaweiusa = "huaweiusa",
  iproxy = "iproxy",
  localtonet = "localtonet",
  mikrotik = "mikrotik",
  quectel = "quectel",
  virtual = "virtual",
  zte = "zte",
}

export enum ModemResetType {
  net = "net",
  power = "power",
  web = "web",
}

export enum ModemStatus {
  disconnected = "disconnected",
  offline = "offline",
  online = "online",
  reset = "reset",
}

export enum PoolKind {
  fixed = "fixed",
  rotated = "rotated",
}

export enum PortCredentialsKind {
  ip = "ip",
  password = "password",
}

export enum PortTargetKind {
  empty = "empty",
  modem = "modem",
  pool = "pool",
}

export enum TopUpPeriod {
  day = "day",
  month = "month",
  week = "week",
}

export enum TopUpSource {
  anymoney = "anymoney",
  binance = "binance",
  ipay = "ipay",
  manual = "manual",
  pspark = "pspark",
  stripe = "stripe",
  wfp = "wfp",
  whitepay = "whitepay",
}

export enum UserStatus {
  active = "active",
  blocked = "blocked",
  disconnected = "disconnected",
}

export interface APServerUpdateInput {
  ip: string;
  host: string;
  countryCode: string;
  nodeConnections: NodeConnectionInput[];
}

export interface AdminUpdateInput {
  role?: string | null;
  password?: string | null;
  routerAccess?: boolean | null;
}

export interface BannerUpdateInput {
  name: string;
  active: boolean;
  enText: string;
  ruText: string;
}

export interface CreateBonusWithdrawalRequestInput {
  userId: string;
  amount: number;
  wallet: string;
  network: string;
}

export interface ModemUpdateInput {
  iface?: string | null;
  proxyPort?: string | null;
  webHost?: string | null;
  webLogin?: string | null;
  webPassword?: string | null;
  externalIp?: string | null;
  manufacturer?: string | null;
  resetType?: string | null;
  comment?: string | null;
  mikrotikIp?: string | null;
  mikrotikLogin?: string | null;
  mikrotikPassword?: string | null;
  mikrotikCommand?: string | null;
  mikrotikOverride?: boolean | null;
  adbDeviceId?: string | null;
}

export interface NodeConnectionInput {
  ip: string;
  node: string;
}

export interface NodeUpdateBurrowedInput {
  id: string;
  uri: string;
}

export interface NodeUpdateInput {
  ipLastOctet?: number | null;
  defaultMikrotikLogin?: string | null;
  defaultMikrotikPassword?: string | null;
  defaultMikrotikResetCommand?: string | null;
}

export interface PlanDatapackInput {
  price: number;
  amount: number;
}

export interface PlanPropertiesInput {
  type: string;
  operator: string;
  proxyType: string;
  maxSpeed: number;
  ipPoolSize: number;
  ipChangeDelay: number;
}

export interface PlanServersInput {
  apServerId: string;
  apServerIp: string;
  apServerHost: string;
  apServerCountryCode: string;
  vpnServerId: string;
}

export interface PlanTarificationInput {
  time: number;
  traffic: number;
  price: number;
}

export interface PlanUpdateInput {
  name?: string | null;
  description?: string | null;
  countryCode?: string | null;
  pool?: string | null;
  reservation?: number | null;
  active?: boolean | null;
  autoRenew?: boolean | null;
  vpnAccess?: boolean | null;
  tarifications?: PlanTarificationInput[] | null;
  partnerTarifications?: PlanTarificationInput[] | null;
  partnerTarifications2?: PlanTarificationInput[] | null;
  partnerTarifications3?: PlanTarificationInput[] | null;
  partnerTarifications4?: PlanTarificationInput[] | null;
  partnerTarifications5?: PlanTarificationInput[] | null;
  datapacks?: PlanDatapackInput[] | null;
  properties?: PlanPropertiesInput | null;
  servers?: PlanServersInput | null;
}

export interface PoolUpdateInput {
  modems?: string[] | null;
}

export interface PortCredentialsInput {
  kind: PortCredentialsKind;
  ipFilter?: string | null;
  login?: string | null;
  digest?: string | null;
  password?: string | null;
  speedLimit: number;
}

export interface PortPlanCurrentInput {
  autoRenew: boolean;
  expiresAt: any;
  trafficRemains: number;
}

export interface PortPlanInput {
  plan: string;
  tarification: PlanTarificationInput;
  recharge: boolean;
  updateTarget: boolean;
  updateExpiration: boolean;
}

export interface PortTargetInput {
  kind: PortTargetKind;
  poolName?: string | null;
  modemId?: string | null;
}

export interface PortUpdateInput {
  refreshApiToken?: boolean | null;
  vpnAccess?: boolean | null;
  speedLimit?: number | null;
  skipTimeoutRelease?: boolean | null;
  autoResetOffline?: boolean | null;
  autoResetInterval?: number | null;
}

export interface PromoUpdateInput {
  name: string;
  token: string;
  active: boolean;
}

export interface ReferrerTokenUpdateInput {
  name: string;
  active: boolean;
}

export interface ResolveBonusWirthdrawalRequestInput {
  comment: string;
  adminNote: string;
}

export interface RolePermissionInput {
  id: string;
  read: boolean;
  write: boolean;
}

export interface SearchInput {
  filter?: string | null;
  sorter?: string | null;
  page?: number | null;
  pageSize?: number | null;
}

export interface SearchLogInput {
  from: any;
  to: any;
  page: number;
  pageSize: number;
  actions: LogAction[];
  sources: LogSource[];
  facilities: LogFacility[];
  causes: string[];
  subjects: string[];
}

export interface SearchPaymentHistoryInput {
  from: any;
  to: any;
  page: number;
  pageSize: number;
}

export interface SearchPoolAssignmentLogInput {
  from: any;
  to: any;
  filter: string;
  action: string;
  page: number;
  pageSize: number;
}

export interface SearchPortLogInput {
  from: any;
  to: any;
  page: number;
  pageSize: number;
}

export interface SearchPromoEventInput {
  from: any;
  to: any;
  kind: number;
  page: number;
  pageSize: number;
}

export interface SearchPromoPaymentsInput {
  from: any;
  to: any;
  page: number;
  pageSize: number;
}

export interface SearchRouterAccessLogInput {
  from: any;
  to: any;
  filter: string;
  page: number;
  pageSize: number;
}

export interface TopUpSummaryInput {
  from: any;
  to: any;
  period: TopUpPeriod;
}

export interface UserReferrerSettingsInput {
  enabled: boolean;
  percent: number;
  gateways: string[];
}

export interface UserServersInput {
  apServerId: string;
  apServerIp: string;
  apServerHost: string;
  apServerCountryCode: string;
  vpnServerId: string;
}

export interface UserSettingsInput {
  activationEnabled: boolean;
}

export interface UserUpdateInput {
  email?: string | null;
  password?: string | null;
  group?: string | null;
  status?: UserStatus | null;
  portsLimit?: number | null;
  refreshApiToken?: boolean | null;
  balanceLimit?: number | null;
  discountPercent?: number | null;
  discountBulkEnabled?: boolean | null;
  allowedGateways?: string[] | null;
  allowAutoResetInterval?: boolean | null;
  defaultSpeedLimit?: number | null;
  defaultTimeoutRelease?: boolean | null;
  defaultAutoResetOffline?: boolean | null;
  referrerSettings?: UserReferrerSettingsInput | null;
  servers?: UserServersInput | null;
}

export interface VPNPortUpdateInput {
  credentials?: PortCredentialsInput[] | null;
  target?: string | null;
  dnsHijack?: boolean | null;
  dnsServer?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
