import { Form, Input } from "antd";
import gql from "graphql-tag";
import React, { useState } from "react";
import i18n from "../../common/i18n";
import { CreateForm } from "../../containers/CreateForm";
import { useMutation } from "../../hooks";
import { InputCountry } from "../Inputs";
import * as g from "./__generated__/CreateAPServer";

const CREATE_AP_SERVER = gql`
  mutation CreateAPServer($ip: String!, $host: String!, $countryCode: String!) {
    createAPServer(ip: $ip, host: $host, countryCode: $countryCode) {
      id
      ip
      host
      heartbeat
      countryCode
      nodeConnections {
        ip
        node
      }
      createdAt
      updatedAt
    }
  }
`;

export const APServerCreate = () => {
  const [form] = Form.useForm<g.CreateAPServerVariables>();
  const [isOpen, toggle] = useState(false);

  const [createAPServer] = useMutation<{
    createAPServer: g.CreateAPServer,
    admin: g.CreateAPServer_createAPServer,
  }>(CREATE_AP_SERVER, {
    okText: i18n.t("router:AP server created"),
    refetchQueries: ["SearchAPServer"],
    onCompleted: (_) => {
      form.resetFields();
      toggle(false);
    },
  });

  return (
    <CreateForm
      name="create-ap-server"
      title={i18n.t("router:Create AP server")}
      form={form}
      isOpen={isOpen}
      toggleOpen={() => toggle(!isOpen)}
      onCreate={(values) => createAPServer({ variables: values })}
    >
      <Form.Item
        name="ip"
        label={i18n.t("router:IP")}
        rules={[{ required: true }]}
      >
        <Input placeholder="192.168.1.1" />
      </Form.Item>
      <Form.Item
        name="host"
        label={i18n.t("router:Host")}
        rules={[{ required: true }]}
      >
        <Input placeholder="ap1.example.com" />
      </Form.Item>
      <Form.Item
        name="countryCode"
        label={i18n.t("router:Country")}
        rules={[{ required: true }]}
      >
        <InputCountry />
      </Form.Item>
    </CreateForm>
  );
}; 