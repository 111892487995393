import { Select, Button, Card, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Space, Spin, Statistic, Switch, Table, Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import gql from "graphql-tag";
import moment from "moment";
import React, { useReducer, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { formatCurrency, formatDate } from "../../common/formatter";
import { useMutation, useQuery } from "../../hooks";
import { OrderInfo } from "../Finance/OrderInfo";
import * as g from "./__generated__/PaymentHistory";
import * as gb from "./__generated__/UpdateBalance";
import * as gx from "./__generated__/UpdateUserFinance";
import * as gf from "./__generated__/UserFinance";
import * as gr from "./__generated__/CreateBonusWithdrawalRequest";
import { useHistory } from "react-router-dom";
import { InputUSD } from "../Inputs";

const { RangePicker } = DatePicker;

const GET_USER = gql`
  query UserFinance($id: ID!) {
    user(id: $id) {
      id
      balance
      balanceLimit
      balanceBonus
      balanceBonusBlocked
      discountPercent
      discountBulkEnabled
      discountBulkPercent
      allowedGateways
      portsLimit
      portsCount
      portsCountActive
    }
  }
`;

const PAYMENT_HISTORY = gql`
  query PaymentHistory($user: ID!, $account: String!, $input: SearchPaymentHistoryInput!) {
    searchPaymentHistory(user: $user, account: $account, input: $input) {
      data {
        status
        amount
        balance
        account
        gateway
        date
        source
        sourceName
        description
      }
      page
      pageSize
      total
    }
  }
`;

const UPDATE_BALANCE = gql`
  mutation UpdateBalance($id: ID!, $amount: Int!, $account: String!, $description: String!) {
    updateBalanceUser(id: $id, amount: $amount, account: $account, description: $description) {
      id
      balance
      balanceBonus
      balanceBonusBlocked
      portsLimit
      portsCount
    }
  }
`;

const UPDATE_FINANCE = gql`
  mutation UpdateUserFinance($id: ID!, $input: UserUpdateInput!) {
    updateUser(id: $id, input: $input) {
      id
      balance
      balanceLimit
      balanceBonus
      balanceBonusBlocked
      discountPercent
      discountBulkEnabled
      discountBulkPercent
      allowedGateways
      portsLimit
      portsCount
      portsCountActive
    }
  }
`;

const CREATE_BONUS_WITHDRAWAL_REQUEST = gql`
  mutation CreateBonusWithdrawalRequest($input: CreateBonusWithdrawalRequestInput!) {
    createBonusWithdrawalRequest(input: $input) {
      id
    }
  }
`;

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 16 },
};


const renderStatus = (record: g.PaymentHistory_searchPaymentHistory_data) => {
  if (record.status === "success") {
    return <Tag color="success">success</Tag>;
  }

  return <Tag color="error">failed</Tag>;
};

const renderAmount = (record: g.PaymentHistory_searchPaymentHistory_data) => {
  return (
    <Typography.Text
      type={(record.amount < 0) ? "danger" : "success"}
    >
      {formatCurrency(record.amount / 100)}
    </Typography.Text>
  );
};

const renderDescription = (text: string) => {
  const orderID = text.match(/Order ([0-9a-fA-F]{24})/);
  if (orderID) {
    return (
      <Space>
        {text}
        <OrderInfo id={orderID[1]} />
      </Space>
    );
  }

  return text;
};

const columns: ColumnsType<g.PaymentHistory_searchPaymentHistory_data> = [
  {
    key: "status",
    title: "Status",
    render: renderStatus,
  },
  {
    key: "source",
    title: "Source",
    render: (record) => record.sourceName === "system" ? record.sourceName : <Link to={`/admins/${record.source}`}>{record.sourceName}</Link>,
  },
  {
    key: "description",
    title: "Description",
    render: (record) => renderDescription(record.description),
  },
  {
    key: "date",
    title: "Date",
    render: (record) => formatDate(record.date),
  },
  {
    dataIndex: "gateway",
    title: "Gateway",
  },
  {
    key: "account",
    title: "Account",
    render: (record) => <Tag color={record.account === "regular" ? "blue" : "yellow"}>{record.account}</Tag>
  },
  {
    key: "amount",
    title: "Amount",
    align: "right",
    render: renderAmount,
  },
  {
    key: "balance",
    title: "Balance",
    align: "right",
    render: (record) => formatCurrency(record.balance / 100),
  },
];

interface PaymentHistoryState {
  from: Date
  to: Date
  account: string
  page: number
  pageSize: number
}

const initialState: PaymentHistoryState = {
  from: moment().startOf("month").toDate(),
  to: moment().endOf("month").toDate(),
  account: "all",
  page: 1,
  pageSize: 20,
};

const paymentHistoryReducer = (
  state: PaymentHistoryState,
  action: { type: string, data: any }
) => {
  switch (action.type) {
    case "DATES":
      return { ...state, from: action.data[0], to: action.data[1] };
    case "ACCOUNT":
      return { ...state, account: action.data };
    case "PAGINATE":
      return { ...state, page: action.data.page, pageSize: action.data.pageSize };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

export const UserFinance = () => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const history = useHistory();

  const [allowedGateways, setAllowedGateways] = useState([] as string[]);
  const toggleGateway = (gateway: string) => {
    if (allowedGateways.includes(gateway)) {
      setAllowedGateways(allowedGateways.filter(v => v !== gateway));
    } else {
      setAllowedGateways([...allowedGateways, gateway]);
    }
  };

  const { loading, data } = useQuery<gf.UserFinance, gf.UserFinanceVariables>(GET_USER, {
    fetchPolicy: "no-cache",
    variables: { id },
    onCompleted: (data) => {
      setAllowedGateways(data.user.allowedGateways);
    }
  });

  const [state, dispatch] = useReducer(paymentHistoryReducer, initialState);
  const paymentHistory = useQuery<g.PaymentHistory, g.PaymentHistoryVariables>(PAYMENT_HISTORY, {
    fetchPolicy: "no-cache",
    variables: {
      user: id,
      account: state.account,
      input: {
        from: state.from,
        to: state.to,
        page: state.page,
        pageSize: state.pageSize
      }
    },
  });

  const [updateBalance] = useMutation<gb.UpdateBalance, gb.UpdateBalanceVariables>(UPDATE_BALANCE, {
    okText: "Balance updated",
    refetchQueries: ["User", "UserFinance","PaymentHistory"],
  });

  const [updateFinance] = useMutation<gx.UpdateUserFinance, gx.UpdateUserFinanceVariables>(UPDATE_FINANCE, {
    okText: "User updated",
    refetchQueries: ["User", "UserFinance"],
  });


  const [createBonusWithdrawalRequest] = useMutation<gr.CreateBonusWithdrawalRequest, gr.CreateBonusWithdrawalRequestVariables>(CREATE_BONUS_WITHDRAWAL_REQUEST, {
    okText: "Request created",
    refetchQueries: ["User", "UserFinance", "PaymentHistory"],
    onCompleted: (data) => {
      history.push(`/finance/bonus-withdrawal/${data.createBonusWithdrawalRequest.id}`);
    }
  });

  if (loading) {
    return <Card><Spin /></Card>;
  }

  return (
    <Card>
      <Card.Grid style={{ width: "50%", outline: "none" }} hoverable={false}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Row gutter={24} justify="center">
            <Col span={12}>
              <Statistic
                title="Balance"
                value={data!.user.balance / 100}
                precision={2}
                prefix="$"
              />
            </Col>
            <Col span={12}>
              <Statistic
                title="Bonus Balance"
                value={data!.user.balanceBonus / 100}
                precision={2}
                prefix="$"
                suffix={
                  data!.user.balanceBonusBlocked > 0 && (
                    <Tag color="warning" style={{marginLeft: 8}}>
                      ${(data!.user.balanceBonusBlocked / 100).toFixed(2)} blocked
                    </Tag>
                  )
                }
              />
            </Col>
          </Row>

          <Row gutter={24} justify="center" style={{ marginTop: 24 }}>
            <Col span={12}>
              <Statistic 
                title="Partner Discount" 
                value={data?.user.discountPercent} 
                suffix="%" 
              />
            </Col>
            <Col span={12}>
              <Statistic 
                title="Bulk Discount" 
                value={data?.user.discountBulkPercent} 
                suffix="%" 
              />
            </Col>
          </Row>

          <Row gutter={24} justify="center" style={{ marginTop: 24 }}>
            <Col span={12}>
              <Statistic 
                title="Total Ports" 
                value={data?.user.portsCount} 
                suffix={` / ${data?.user.portsLimit === 0 ? "∞" : data?.user.portsLimit}`}
              />
            </Col>
            <Col span={12}>
              <Statistic 
                title="Active Ports"
                value={data?.user.portsCountActive}
                suffix={` / ${data?.user.portsLimit === 0 ? "∞" : data?.user.portsLimit}`}
              />
            </Col>
          </Row>
        </Space>

        <br /><br /><br /><br />
        <Space style={{ width: "100%" }} direction="vertical">
          <h3>Balance Settings</h3>
          <Form
            {...formLayout}
            form={form}
            name="edit-finance"
            initialValues={data!.user}
            onFinish={(values) => updateFinance({ variables: { id, input: { ...values, allowedGateways: allowedGateways } } })}
          >
            <Form.Item
              label="Overdraft"
              name="balanceLimit"
            >
              <InputNumber
                max={0}
                min={-10000000}
                step={100}
                addonBefore="$"
                parser={value => (parseInt((value || "").replace(/\$\s?|(,*)/g, ""), 10) * 100) as 0 | -10000000}
                formatter={value => `${(value || 0) / 100}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              />
            </Form.Item>

            <br />

            <Form.Item
              label="Bulk Discount"
              name="discountBulkEnabled"
              valuePropName="checked"
            >
              <Switch checkedChildren="Enabled" unCheckedChildren="Disabled" />
            </Form.Item>

            <Form.Item
              label="Partner Discount"
              name="discountPercent"
            >
              <InputNumber
                min={0}
                max={100}
                addonAfter="%"
              />
            </Form.Item>

            <br />

            <Form.Item
              label="Allowed Gateways"
            >
              <Space direction="vertical">
                <Space>
                  <Switch checkedChildren="wayforpay" unCheckedChildren="wayforpay" checked={allowedGateways.includes("wfp")} onChange={() => toggleGateway("wfp")} />
                  <Switch checkedChildren="any.money" unCheckedChildren="any.money" checked={allowedGateways.includes("anymoney")} onChange={() => toggleGateway("anymoney")} />
                  <Switch checkedChildren="Binance Pay" unCheckedChildren="Binance Pay" checked={allowedGateways.includes("binance")} onChange={() => toggleGateway("binance")} />
                </Space>
                <Space>
                  <Switch checkedChildren="Whitepay" unCheckedChildren="Whitepay" checked={allowedGateways.includes("whitepay")} onChange={() => toggleGateway("whitepay")} />
                  <Switch checkedChildren="Stripe" unCheckedChildren="Stripe" checked={allowedGateways.includes("stripe")} onChange={() => toggleGateway("stripe")} />
                  <Switch checkedChildren="iPay" unCheckedChildren="iPay" checked={allowedGateways.includes("ipay")} onChange={() => toggleGateway("ipay")} />
                  <Switch checkedChildren="PSPark" unCheckedChildren="PSPark" checked={allowedGateways.includes("pspark")} onChange={() => toggleGateway("pspark")} />
                </Space>
              </Space>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button
                htmlType="submit"
                type="primary"
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Card.Grid>

      <Card.Grid style={{ width: "50%", outline: "none" }} hoverable={false}>
        <h3>Adjust account balance</h3><br />
        <Form
          {...formLayout}
          initialValues={{ action: "topup", account: "regular", amount: 0, description: "" }}
          onFinish={(values) => {
            if (values.action === "topup") {
              updateBalance({ variables: { id, amount: values.amount, account: values.account, description: values.description } });
            } else {
              updateBalance({ variables: { id, amount: -values.amount, account: values.account, description: values.description } });
            }
          }}
        >
          <Form.Item
            name="account"
            label="Account"
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="regular">Regular</Radio.Button>
              <Radio.Button value="bonus">Bonus</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="action"
            label="Action"
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="topup">Top Up</Radio.Button>
              <Radio.Button value="withdraw">Withdraw</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="amount"
            label="Amount"
          >
            <InputUSD style={{ width: "160px" }} />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
          >
            <Input placeholder="Manual operation" />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button
              htmlType="submit"
              type="primary"
            >
              Create
            </Button>
          </Form.Item>
        </Form>
      </Card.Grid>

      <Card.Grid style={{ width: "50%", outline: "none" }} hoverable={false}>
        <h3>Request bonus withdrawal</h3><br />
        <Form
          {...formLayout}
          initialValues={{ amount: 0, wallet: "", network: "USDT TRC20" }}
          onFinish={(values) => {
            createBonusWithdrawalRequest({ variables: { input: { userId: id, amount: values.amount, wallet: values.wallet, network: values.network } } });
          }}
        >
          <Form.Item
            name="amount"
            label="Amount"
          >
            <InputUSD style={{ width: "160px" }} />
          </Form.Item>
          <Form.Item
            name="wallet"
            label="Wallet"
          >
            <Input placeholder="Wallet address" />
          </Form.Item>
          <Form.Item
            name="network"
            label="Network"
          >
            <Select>
              <Select.Option value="USDT TRC20">USDT TRC20</Select.Option>
              <Select.Option value="USDT BEP20">USDT BEP20</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button
              htmlType="submit"
              type="primary"
            >
              Create
            </Button>
          </Form.Item>
        </Form>
      </Card.Grid>

      <Card.Grid style={{ width: "100%", outline: "none" }} hoverable={false}>
        <h3>Payment history</h3><br />
        <Row gutter={24}>
          <Col span={4}>
            <Radio.Group buttonStyle="solid" value={state.account} onChange={(e) => dispatch({ type: "ACCOUNT", data: e.target.value })}>
              <Radio.Button value="all">All</Radio.Button>
              <Radio.Button value="regular">Regular</Radio.Button>
              <Radio.Button value="bonus">Bonus</Radio.Button>
            </Radio.Group>
          </Col>
          <Col span={12}>
            <RangePicker
              allowClear={false}
              showTime={true}
              value={[moment(state.from), moment(state.to)]}
              onChange={(dates) => dispatch({ type: "DATES", data: dates })}
            />
          </Col>
        </Row>
        <br />
        <Table
          size="middle"
          rowKey={record => record.date}
          loading={paymentHistory.loading}
          columns={columns}
          dataSource={paymentHistory.data?.searchPaymentHistory.data}
          pagination={{
            showSizeChanger: true,
            current: state.page,
            pageSize: state.pageSize,
            total: paymentHistory.data?.searchPaymentHistory.total,
          }}
          onChange={(pagination) => dispatch({ type: "PAGINATE", data: { page: pagination.current, pageSize: pagination.pageSize } })}
        />
      </Card.Grid>
    </Card >
  );
};
