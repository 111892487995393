import { CopyOutlined, RetweetOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, InputNumber, message, Popconfirm, Select, Space, Switch, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import gql from "graphql-tag";
import React, { Fragment, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link, useHistory, useParams } from "react-router-dom";
import * as gg from "../../__generated__/globalTypes";
import { formatCurrency } from "../../common/formatter";
import i18n from "../../common/i18n";
import { Date } from "../../components/Date";
import { EditForm } from "../../containers/EditForm";
import { useAccess, useMutation, useQuery } from "../../hooks";
import { InputBandwidth } from "../Inputs";
import { LogListControlled } from "../Log";
import { UserFinance, UserReferralProgram } from "./";
import * as gp from "./__generated__/CreatePort";
import * as gd from "./__generated__/DeleteUser";
import * as gu from "./__generated__/UpdateUser";
import * as g from "./__generated__/User";
import { EditServers } from "./EditServers";

const GET_USER = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      login
      email
      group
      ports
      portResolvers {
        id
        target {
          kind
          pool
          poolName
          modem
        }
        plan {
          name
          enabled
          tarification {
            time
            traffic
            price
          }
          expiresAt
        }
      }
      status
      apiToken
      portsLimit
      allowedGateways
      allowAutoResetInterval
      defaultSpeedLimit
      defaultTimeoutRelease
      defaultAutoResetOffline
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($id: ID!, $input: UserUpdateInput!) {
    updateUser(id: $id, input: $input) {
      id
      login
      email
      group
      status
      apiToken
      portsLimit
      allowAutoResetInterval
      defaultSpeedLimit
      defaultTimeoutRelease
      defaultAutoResetOffline
      createdAt
      updatedAt
    }
  }
`;

const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;

const CREATE_PORT = gql`
  mutation CreatePort($userId: String!) {
    createPort(userId: $userId) {
      id
      user {
        id
        login
        group
      }
      resetToken
      resetAt
      signature {
        id
        raw
      }
      credentials {
        kind
        ipFilter
        login
      }
      target {
        kind
        pool
        poolName
        modem
      }
      createdAt
      updatedAt
    }
  }
`;

const gridStyle = {
  width: "50%",
  outline: "none",
};

const renderTarget = (_text: string, record: any) => {
  switch (record.target.kind) {
    case "modem":
      return <Link to={`/modems/${record.target.modem}`}><Tag color="blue">{record.target.modem}</Tag></Link>;
    case "pool":
      return <Link to={`/pools/${record.target.pool}`}><Tag color="purple">{record.target.poolName}</Tag></Link>;
    default:
      return <Tag>{i18n.t("port:Empty")}</Tag>;
  }
};

const renderPlan = (plan: any) => {
  if (!plan.enabled) {
    return "";
  }

  return plan.name;
};

const renderOption = (plan: any) => {
  if (!plan.enabled) {
    return "";
  }

  const opt = plan.tarification;
  return `${opt.time / 86400} Days / ${opt.traffic <= 0 ? "∞" : opt.traffic / 1024} GB / ${formatCurrency(opt.price / 100)}`;
};

const renderExpiresAt = (plan: any) => {
  if (!plan.enabled) {
    return "";
  }

  return <Date date={plan.expiresAt} />;
};

interface UserEditFields {
  login: string;
  password?: string;
  group?: string;
  status?: string;
  refreshApiToken?: boolean;
}

export const UserEdit = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm<UserEditFields>();

  const [selectedTab, selectTab] = useState("general");
  const { access } = useAccess("finance");

  const { loading, data } = useQuery<g.User, g.UserVariables>(GET_USER, {
    variables: { id },
  });

  const [updateUser] = useMutation<gu.UpdateUser, gu.UpdateUserVariables>(UPDATE_USER, {
    okText: i18n.t("user:User updated"),
    refetchQueries: ["User"],
  });

  const [deleteUser] = useMutation<gd.DeleteUser, gd.DeleteUserVariables>(DELETE_USER, {
    okText: i18n.t("user:User deleted"),
    onCompleted: (_) => {
      history.push("/users");
    }
  });

  const [createPort] = useMutation<gp.CreatePort, gp.CreatePortVariables>(CREATE_PORT, {
    okText: i18n.t("port:Port created"),
    onCompleted: (data) => {
      history.push(`/ports/${data.createPort.id}`);
    }
  });

  var tabList = [
    {
      key: "general",
      tab: "General",
    },
    {
      key: "log",
      tab: "Log"
    },
  ];

  if (access["finance"]) {
    tabList = [
      {
        key: "general",
        tab: "General",
      },
      {
        key: "finance",
        tab: "Finance",
      },
      {
        key: "referral",
        tab: "Referral Program",
      },
      {
        key: "log",
        tab: "Log"
      },
    ];
  }

  const portColumns: ColumnsType<g.User_user_portResolvers> = [
    {
      dataIndex: "id",
      title: i18n.t("port:ID"),
      render: (text, record) => <Link to={`/ports/${record.id}`}>{text}</Link>,
    },
    {
      key: "target",
      title: i18n.t("port:Target"),
      dataIndex: "",
      render: renderTarget,
    },
    {
      key: "plan",
      dataIndex: "plan",
      title: i18n.t("port:Plan"),
      render: renderPlan,
    },
    {
      key: "option",
      dataIndex: "plan",
      title: i18n.t("port:Option"),
      render: renderOption,
    },
    {
      key: "expiresAt",
      dataIndex: "plan",
      title: i18n.t("port:Expires At"),
      render: renderExpiresAt,
    },
  ];

  const editTab = (
    <Card>
      <Card.Grid style={gridStyle} hoverable={false}>
        <h3>{i18n.t("user:General")}</h3><br />
        <EditForm
          name="edit-user"
          deleteTitle={i18n.t("user:Delete user and ALL it's ports?")}
          loading={loading}
          form={form}
          initialValues={data?.user}
          onSave={(values) => updateUser({ variables: { id, input: { ...values, status: values.status as gg.UserStatus } } })}
          onDelete={() => deleteUser({ variables: { id } })}
        >
          <Form.Item
            name="email"
            label={i18n.t("user:E-Mail")}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label={i18n.t("user:Password")}
            extra={i18n.t("user:Leave empty to preserve current password")}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="status"
            label={i18n.t("user:Status")}
          >
            <Select style={{ width: 160 }}>
              <Select.Option value="active">{i18n.t("user:Active")}</Select.Option>
              <Select.Option value="blocked">{i18n.t("user:Blocked")}</Select.Option>
              <Select.Option value="disconnected">{i18n.t("user:Disconnected")}</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="group"
            label={i18n.t("user:Group")}
          >
            <Select style={{ width: 160 }}>
              <Select.Option value="default">Default</Select.Option>
              <Select.Option value="partner">Partner1</Select.Option>
              <Select.Option value="partner2">Partner2</Select.Option>
              <Select.Option value="partner3">Partner3</Select.Option>
              <Select.Option value="partner4">Partner4</Select.Option>
              <Select.Option value="partner5">Partner5</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="apiToken"
            label={i18n.t("user:API token")}
          >
            <Input
              disabled={true}
              addonAfter={
                <Popconfirm
                  title={i18n.t("user:Refresh API token?")}
                  okText={i18n.t("user:Yes")}
                  cancelText={i18n.t("user:No")}
                  onConfirm={() => updateUser({ variables: { id, input: { refreshApiToken: true } } })}
                >
                  <RetweetOutlined />
                </Popconfirm>
              }
              addonBefore={
                <CopyToClipboard
                  text={data?.user.apiToken || ""}
                  onCopy={() => message.info(i18n.t("user:Token copied"))}
                >
                  <CopyOutlined />
                </CopyToClipboard>
              }
            />
          </Form.Item>
          <Form.Item
            name="portsLimit"
            label={i18n.t("user:Port Limit")}
            extra={i18n.t("user:0 means no limit, only affects user cabinet")}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            name="defaultSpeedLimit"
            label="Port Speed Limit"
            extra="Default value for all new ports"
          >
            <InputBandwidth />
          </Form.Item>
          <Form.Item
            name="allowAutoResetInterval"
            label="Allow auto reset by interval"
            extra="Affects all users port"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="defaultTimeoutRelease"
            label="Disable release by inactivity"
            extra="Default value for all new ports"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="defaultAutoResetOffline"
            label="Auto reset in case of offline"
            extra="Default value for all new ports"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </EditForm>
        <br />
        <EditServers />
      </Card.Grid>
      <Card.Grid style={gridStyle} hoverable={false}>
        <h3>{i18n.t("user:Ports")}</h3><br />
        <Space direction="vertical">
          <Button onClick={() => createPort({ variables: { userId: data!.user.id } })}>Create port</Button>
          <Table
            size="small"
            rowKey={record => record.id}
            columns={portColumns}
            dataSource={data?.user.portResolvers}
            pagination={false}
          />
        </Space>
      </Card.Grid>
    </Card>
  );

  return (
    <Fragment>
      <Card
        title={data?.user.login}
        bordered={false}
        headStyle={{ padding: "0" }}
        bodyStyle={{ padding: "24px 0" }}
        tabList={tabList}
        activeTabKey={selectedTab}
        onTabChange={(tabKey) => selectTab(tabKey)}
      >
        {selectedTab === "general" && editTab}
        {selectedTab === "finance" && access["finance"] && <UserFinance />}
        {selectedTab === "referral" && <UserReferralProgram />}
        {selectedTab === "log" && data?.user && <LogListControlled subjects={[data!.user.id, ...data!.user.ports]} />}
      </Card>
    </Fragment>
  );
};
