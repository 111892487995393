import { InputNumber } from "antd";
import React from "react";

const currencyParser = (value?: string) => {
  const numValue = parseFloat(value || "");
  return Number.isNaN(numValue) ? 0 : Math.round(numValue * 100);
};

const currencyFormatter = (value: number | undefined, info: { userTyping: boolean, input: string }) => {
  if (!value) {
    return "0.00";
  }

  const numValue = value / 100;
  return info.userTyping ? numValue.toString() : numValue.toFixed(2);
};

export interface InputUSDProps {
  value?: number;
  style?: React.CSSProperties;
  onChange?: (value: number) => void;
}

export const InputUSD = (props: InputUSDProps) => {
  return (
    <InputNumber
      style={props.style} 
      prefix="$"
      parser={currencyParser}
      formatter={currencyFormatter}
      min={0}
      step={100}
      value={props.value}
      onChange={props.onChange}
    />
  );
};
