import { CheckCircleTwoTone, ExclamationCircleTwoTone } from "@ant-design/icons";
import { Button, Popconfirm, Popover } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import gql from "graphql-tag";
import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import i18n from "../../common/i18n";
import { Date } from "../../components/Date";
import { ListContainer } from "../../containers/ListContainer";
import { useMutation, useSearch } from "../../hooks";
import * as g from "./__generated__/SearchAPServer";
import * as gd from "./__generated__/DeleteAPServer";
import { APServerCreate } from "./CreateServer";
import { emojiFlag } from "../Inputs";

const SEARCH_AP_SERVER = gql`
    query SearchAPServer($input: SearchInput!) {
        searchAPServer(input: $input) {
            data {
                id
                ip
                host
                heartbeat
                countryCode
                nodeConnections {
                    ip
                    node
                }
                createdAt
                updatedAt
            }
            page
            pageSize
            total
        }
    }
`;

const DELETE_AP_SERVER = gql`
    mutation DeleteAPServer($id: ID!) {
        deleteAPServer(id: $id)
    }
`;

const renderHeartbeat = (now: any) => (_text: string, record: g.SearchAPServer_searchAPServer_data) => {
  const [nowTs, heartbeatTs] = [
    moment(now).subtract(1, "minute"),
    moment(record.heartbeat),
  ];

  let icon = <CheckCircleTwoTone twoToneColor="green" />;
  if (heartbeatTs < nowTs) {
    icon = <ExclamationCircleTwoTone twoToneColor="orange" />;
  }

  return (
    <Popover
      title={i18n.t("router:Healthcheck")}
      content={<p>{i18n.t("router:Synced")} {heartbeatTs.fromNow()}</p>}
    >
      {icon}
    </Popover>
  );
};

const renderNodeConnections = (_text: string, record: g.SearchAPServer_searchAPServer_data) => {
    return <span>{record.nodeConnections.length}</span>;
};

export const APServerList = () => {
  const [nowTs, setNowTs] = useState(moment());

  const { result, search, updateFilter, updateTable } = useSearch<g.SearchAPServer>(SEARCH_AP_SERVER, {
    onCompleted: () => {
      setNowTs(moment());
    }
  });

  const [deleteAPServer] = useMutation<gd.DeleteAPServer, gd.DeleteAPServerVariables>(DELETE_AP_SERVER, {
    okText: i18n.t("router:AP server deleted"),
    refetchQueries: ["SearchAPServer"],
  });

  const columns: ColumnsType<g.SearchAPServer_searchAPServer_data> = [
    {
      dataIndex: "id",
      title: i18n.t("router:ID"),
      sorter: true,
      render: (text, record) => (
        <Link to={`/routers/servers/${record.id}`}>
          {text}
        </Link>
      ),
    },
    {
      dataIndex: "ip",
      title: i18n.t("router:IP"),
      sorter: true,
    },
    {
      dataIndex: "host",
      title: i18n.t("router:Host"),
      sorter: true,
    },
    {
      dataIndex: "countryCode",
      title: i18n.t("router:Country"),
      render: (text, record) => (
        <span>{emojiFlag(record.countryCode)}</span>
      ),
    },
    {
      key: "status",
      title: i18n.t("router:Status"),
      render: renderHeartbeat(nowTs),
    },
    {
      key: "nodeConnections",
      title: i18n.t("router:Node Connections"),
      render: renderNodeConnections,
    },
    {
      dataIndex: "updatedAt",
      title: i18n.t("router:Updated At"),
      sorter: true,
      render: text => <Date date={text} />,
      defaultSortOrder: "descend" as SortOrder,
    },
    {
      key: "Delete",
      title: i18n.t("router:Delete"),
      render: (_, record) => {
        return (
          <Popconfirm
            title={i18n.t("router:Delete AP server?")}
            okText={i18n.t("router:Yes")}
            cancelText={i18n.t("router:No")}
            onConfirm={() => deleteAPServer({ variables: { id: record.id } })}
          >
            <Button htmlType="button">
              {i18n.t("router:Delete")}
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <ListContainer
      create={<APServerCreate />}
      search={search}
      columns={columns}
      data={result.data?.searchAPServer.data}
      total={result.data?.searchAPServer.total}
      loading={result.loading}
      onSearch={updateFilter}
      onTableChange={updateTable}
    />
  );
};

