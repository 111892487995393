import { Button, Card, Form, Input, Select, Space, Typography, List } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { EditForm } from "../../containers/EditForm";
import { InputCountry } from "../Inputs";
import { useMutation, useQuery } from "../../hooks";
import gql from "graphql-tag";

const GET_AP_SERVER = gql`
  query APServer($id: ID!) {
    apServer(id: $id) {
      id
      ip
      host
      heartbeat
      countryCode
      nodeConnections {
          ip
          node
      }
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_AP_SERVER = gql`
  mutation UpdateAPServer($id: ID!, $input: APServerUpdateInput!) {
    updateAPServer(id: $id, input: $input) {
      id  
      ip
      host
      heartbeat
      countryCode
      nodeConnections {
          ip
          node
      }
      createdAt
      updatedAt
    }
  }
`;

const GET_ALL_NODES = gql`
  query AllNodes($input: SearchInput!) {
    searchNode(input: $input) {
      data {
        id
        ipLastOctet
      }
    }
  }
`;

export const APServerEdit = () => {
  const { t } = useTranslation("apserver");
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();

  // Query for server data
  const { loading, data } = useQuery<any>(GET_AP_SERVER, {
    variables: { id },
    onCompleted: (data) => {
      const connections = [...(data.apServer.nodeConnections || [])];
      const sortedConnections = connections.sort((a: any, b: any) => 
        a.node.localeCompare(b.node)
      );
      setNodeConnections(sortedConnections);
    }
  });

  // Query for all nodes with proper variables
  const { data: nodesData } = useQuery<any>(GET_ALL_NODES, {
    variables: {
      input: {
        filter: "",
        page: 1,
        pageSize: 200 // Adjust this value based on your needs
      }
    }
  });

  // Update mutation
  const [updateAPServer] = useMutation(UPDATE_AP_SERVER, {
    okText: t("AP Server updated"),
    refetchQueries: ["APServer"]
  });

  const [nodeConnections, setNodeConnections] = useState<Array<{ ip: string; node: string }>>([]);
  const [newConnection, setNewConnection] = useState<{ ip: string; node: string }>({ ip: "", node: "" });
  const [isAdding, setIsAdding] = useState(false);

  const addNodeConnection = () => {
    if (newConnection.ip && newConnection.node) {
      const newConnections = [...nodeConnections, newConnection].sort((a, b) => 
        a.node.localeCompare(b.node)
      );
      setNodeConnections(newConnections);
      setNewConnection({ ip: "", node: "" });
      setIsAdding(false);
    }
  };

  const removeNodeConnection = (index: number) => {
    setNodeConnections(nodeConnections.filter((_, i) => i !== index));
  };

  const handleSave = (values: any) => {
    updateAPServer({
      variables: {
        id,
        input: {
          ...values,
          nodeConnections
        }
      }
    });
  };

  return (
    <Card title={data?.apServer.host} bordered={false} headStyle={{ padding: "0" }} bodyStyle={{ padding: "24px 0" }}>
      <Card.Grid style={{ width: "100%" }} hoverable={false}>
        <EditForm
          name="edit-ap-server"
          loading={loading}
          form={form}
          initialValues={data?.apServer}
          onSave={handleSave}
        >
          <Form.Item
            name="ip"
            label={t("IP Address")}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="host"
            label={t("Host")}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="countryCode"
            label={t("Country Code")}
            rules={[{ required: true, len: 2 }]}
          >
            <InputCountry />
          </Form.Item>

          <Form.Item label={t("Node Connections")}>
            <Space direction="vertical" style={{ width: "100%" }}>
              {!isAdding ? (
                <Button 
                  type="dashed" 
                  onClick={() => setIsAdding(true)}
                  icon={<PlusOutlined />}
                  size="small"
                >
                  {t("Add Node Connection")}
                </Button>
              ) : (
                <Space direction="vertical" size={4}>
                  <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                    <Select
                      value={newConnection.node}
                      onChange={(value) => setNewConnection({ ...newConnection, node: value })}
                      showSearch={true}
                      style={{ width: "200px" }}
                      size="small"
                      placeholder={t("Select Node")}
                    >
                      {nodesData?.searchNode.data
                        .slice()
                        .sort((a: any, b: any) => a.id.localeCompare(b.id))
                        .map((node: any) => (
                          <Select.Option key={node.id} value={node.id}>
                            {node.id} (IP: {node.ipLastOctet})
                          </Select.Option>
                        ))}
                    </Select>
                    <Input
                      value={newConnection.ip}
                      onChange={(e) => setNewConnection({ ...newConnection, ip: e.target.value })}
                      placeholder="IP Address"
                      style={{ width: "200px" }}
                      size="small"
                    />
                    <Button 
                      type="primary"
                      size="small"
                      disabled={!newConnection.ip || !newConnection.node}
                      onClick={addNodeConnection}
                    >
                      {t("Add")}
                    </Button>
                    <Button 
                      size="small"
                      onClick={() => {
                        setIsAdding(false);
                        setNewConnection({ ip: "", node: "" });
                      }}
                    >
                      {t("Cancel")}
                    </Button>
                  </div>
                </Space>
              )}
              
              <List
                size="small"
                dataSource={nodeConnections}
                style={{ marginTop: "8px" }}
                renderItem={(item, index) => (
                  <List.Item style={{ padding: "4px 0" }}>
                    <div style={{ display: "flex", gap: "8px", alignItems: "center", width: "100%" }}>
                      <Button 
                        type="text"
                        danger
                        icon={<DeleteOutlined />}
                        size="small"
                        onClick={() => removeNodeConnection(index)}
                      />
                      <Typography.Text style={{ flex: 1, fontSize: "12px" }}>{item.node}</Typography.Text>
                      <Typography.Text style={{ width: "200px", fontSize: "12px" }}>{item.ip}</Typography.Text>
                    </div>
                  </List.Item>
                )}
              />
            </Space>
          </Form.Item>
        </EditForm>
      </Card.Grid>
    </Card>
  );
};

