import { Form, Input, InputNumber, Select } from "antd";
import gql from "graphql-tag";
import React, { useState } from "react";
import * as gg from "../../__generated__/globalTypes";
import i18n from "../../common/i18n";
import { CreateForm } from "../../containers/CreateForm";
import { useMutation } from "../../hooks";
import * as g from "./__generated__/CreateModem";
import * as gv from "./__generated__/CreateModemVirtual";

const CREATE_MODEM = gql`
  mutation CreateModem($id: String!, $iface: String!, $proxyPort: String!, $webHost: String!, $externalIp: String!, $manufacturer: ModemManufacturer!) {
    createModem(id: $id, iface: $iface, proxyPort: $proxyPort, webHost: $webHost, externalIp: $externalIp, manufacturer: $manufacturer) {
      id
      iface
      proxyPort
      webHost
      webLogin
      webPassword
      externalIp
      manufacturer
      status
      wanIp
      createdAt
      updatedAt
    }
  }
`;

const CREATE_MODEM_VIRTUAL = gql`
  mutation CreateModemVirtual($id: String!, $proxy: String!, $resetLink: String!) {
    createModemVirtual(id: $id, proxy: $proxy, resetLink: $resetLink) {
      id
      iface
      proxyPort
      webHost
      webLogin
      webPassword
      externalIp
      manufacturer
      status
      wanIp
      createdAt
      updatedAt
    }
  }
`;

export const ModemCreate = () => {
  const [form] = Form.useForm<g.CreateModemVariables | gv.CreateModemVirtualVariables>();
  const [isOpen, toggle] = useState(false);
  const [manufacturer, setManufacturer] = useState<string>(gg.ModemManufacturer.huawei);

  const [createModem] = useMutation<{
    createModem: g.CreateModem,
    modem: g.CreateModem_createModem,
  }>(CREATE_MODEM, {
    okText: i18n.t("modem:Modem created"),
    refetchQueries: ["SearchModem"],
    onCompleted: (_) => {
      form.resetFields();
      toggle(false);
    },
  });

  const [createModemVirtual] = useMutation<{
    createModemVirtual: gv.CreateModemVirtual_createModemVirtual,
  }>(CREATE_MODEM_VIRTUAL, {
    okText: i18n.t("modem:Virtual modem created"),
    refetchQueries: ["SearchModem"],
    onCompleted: (_) => {
      form.resetFields();
      toggle(false);
    },
  });

  const handleManufacturerChange = (value: string) => {
    setManufacturer(value);
    // Reset form fields when switching between regular and virtual modem
    form.resetFields(["iface", "proxyPort", "webHost", "externalIp", "proxy", "resetLink"]);
  };

  const handleCreate = (values: any) => {
    if (manufacturer === "virtual") {
      // For virtual modem, use the createModemVirtual mutation
      const { id, proxy, resetLink } = values;
      createModemVirtual({ 
        variables: { 
          id, 
          proxy, 
          resetLink  
        } 
      });
    } else {
      // For regular modem, use the createModem mutation
      createModem({ variables: values });
    }
  };

  return (
    <CreateForm
      name="create-modem"
      title={i18n.t("modem:Create modem")}
      form={form}
      initialValues={{ "manufacturer": gg.ModemManufacturer.huawei }}
      isOpen={isOpen}
      toggleOpen={() => toggle(!isOpen)}
      onCreate={handleCreate}
    >
      <Form.Item
        name="id"
        label={i18n.t("modem:ID")}
        rules={[{ required: true }]}
      >
        <Input placeholder="UK-VPNCL01-101" />
      </Form.Item>
      <Form.Item
        name="manufacturer"
        label={i18n.t("modem:Manufacturer")}
        rules={[{ required: true }]}
      >
        <Select 
          style={{ width: 150 }} 
          onChange={handleManufacturerChange}
        >
          <Select.Option value="huawei">Huawei E3372</Select.Option>
          <Select.Option value="huawei525">Huawei B525</Select.Option>
          <Select.Option value="huaweiusa">Huawei USA</Select.Option>
          <Select.Option value="mikrotik">MikroTik</Select.Option>
          <Select.Option value="zte">ZTE</Select.Option>
          <Select.Option value="android">Android</Select.Option>
          <Select.Option value="quectel">Quectel</Select.Option>
          <Select.Option value="virtual">Virtual</Select.Option>
        </Select>
      </Form.Item>
      
      {manufacturer === "virtual" ? (
        // Virtual modem fields
        <>
          <Form.Item
            name="proxy"
            label={i18n.t("modem:Proxy")}
            rules={[{ required: true }]}
          >
            <Input placeholder="socks5://proxy.example.com:8080" />
          </Form.Item>
          <Form.Item
            name="resetLink"
            label={i18n.t("modem:Reset URL")}
            rules={[{ required: true }]}
          >
            <Input placeholder="http://reset.example.com/api/reset" />
          </Form.Item>
        </>
      ) : (
        // Regular modem fields
        <>
          <Form.Item
            name="iface"
            label={i18n.t("modem:Interface")}
            rules={[{ required: true }]}
          >
            <Input placeholder="eth1.1001" />
          </Form.Item>
          <Form.Item
            name="proxyPort"
            label={i18n.t("modem:Proxy port")}
            rules={[{ required: true }]}
            normalize={(value) => String(value)}
          >
            <InputNumber placeholder="10001" min={1} max={65535} />
          </Form.Item>
          <Form.Item
            name="webHost"
            label={i18n.t("modem:Web interface host")}
            rules={[{ required: true }]}
          >
            <Input placeholder="192.168.101.1:80" />
          </Form.Item>
          <Form.Item
            name="externalIp"
            label={i18n.t("modem:External IP")}
            rules={[{ required: true }]}
          >
            <Input placeholder="172.16.21.22" />
          </Form.Item>
        </>
      )}
    </CreateForm>
  );
};
