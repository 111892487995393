import { Button, Form, Select, Spin } from "antd";
import gql from "graphql-tag";
import React, { Fragment, useReducer } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "../../hooks";

const GET_PLAN_SERVERS = gql`
  query PlanServers($id: ID!) {
    plan(id: $id) {
      id    
      servers {
        apServerId
        apServerIp
        apServerHost
        apServerCountryCode
        vpnServerId
      }
    }
  }
`;

const UPDATE_PLAN_SERVERS = gql`
  mutation UpdatePlanServers($id: ID!, $input: PlanUpdateInput!) {
    updatePlan(id: $id, input: $input) {
      id
      servers {
        apServerId
        apServerIp
        apServerHost
        apServerCountryCode
        vpnServerId
      }
    }
  }
`;

const SEARCH_ALL_SERVERS = gql`
  query AllPlanServers {
    allAPServer {
      id
      ip
      host
      countryCode
    }
    allVPNServer {
      id
    }
  }
`;

interface EditServersState {
  apServerId: string;
  apServerIp: string;
  apServerHost: string;
  apServerCountryCode: string;
  vpnServerId: string;
  filter: string;
}

const defaultState = {
  apServerId: "",
  apServerIp: "",
  apServerHost: "",
  apServerCountryCode: "",
  vpnServerId: "",
  filter: "",
};

const editServersReducer = (
  state: EditServersState,
  action: { type: string; data: any }
) => {
  switch (action.type) {
    case "INIT":
      return {
        ...state,
        apServerId: action.data.servers?.apServerId || "",
        apServerIp: action.data.servers?.apServerIp || "",
        apServerHost: action.data.servers?.apServerHost || "",
        apServerCountryCode: action.data.servers?.apServerCountryCode || "",
        vpnServerId: action.data.servers?.vpnServerId || "",
      };
    case "SET_AP_SERVER":
      const selectedServer = action.data;
      if (selectedServer === null) {
        return {
          ...state,
          apServerId: "",
          apServerIp: "",
          apServerHost: "",
          apServerCountryCode: "",
        };
      }
      return {
        ...state,
        apServerId: selectedServer.id,
        apServerIp: selectedServer.ip,
        apServerHost: selectedServer.host,
        apServerCountryCode: selectedServer.countryCode,
      };
    case "SET_VPN_SERVER":
      return { ...state, vpnServerId: action.data || "" };
    case "SET_FILTER":
      return { ...state, filter: action.data };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export const EditServers = () => {
  const { id } = useParams<{ id: string }>();
  const [state, dispatch] = useReducer(editServersReducer, defaultState);

  const { loading } = useQuery(GET_PLAN_SERVERS, {
    variables: { id },
    onCompleted: (data) => {
      dispatch({ type: "INIT", data: data.plan });
    },
  });

  const [updatePlan] = useMutation(UPDATE_PLAN_SERVERS, {
    okText: "Servers updated",
    refetchQueries: ["Plan"],
  });

  const { data: serversData, loading: serversLoading } = useQuery(SEARCH_ALL_SERVERS, {
    fetchPolicy: "no-cache",
  });

  if (loading || serversLoading) {
    return <Spin />;
  }

  return (
    <Fragment>
      <h3>Servers</h3>
      <br />
      <Form {...formLayout}>
        <Form.Item label="AP Server">
          <Select
            style={{ width: "100%" }}
            placeholder="Default"
            value={state.apServerId || undefined}
            allowClear
            onClear={() => dispatch({ type: "SET_AP_SERVER", data: null })}
            onSelect={(val: string, option: any) => {
              const server = serversData?.allAPServer.find((s: any) => s.id === val);
              dispatch({ type: "SET_AP_SERVER", data: server });
            }}
          >
            {serversData?.allAPServer.map((server: any) => (
              <Select.Option key={server.id} value={server.id}>
                {`${server.host} (${server.ip})`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="VPN Server">
          <Select
            style={{ width: "100%" }}
            placeholder="Default"
            value={state.vpnServerId || undefined}
            allowClear
            onClear={() => dispatch({ type: "SET_VPN_SERVER", data: "" })}
            onSelect={(val: string) =>
              dispatch({ type: "SET_VPN_SERVER", data: val })
            }
          >
            {serversData?.allVPNServer.map((server: any) => (
              <Select.Option key={server.id} value={server.id}>
                {server.id}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button
            type="primary"
            onClick={() =>
              updatePlan({
                variables: {
                  id,
                  input: {
                    servers: {
                      apServerId: state.apServerId,
                      apServerIp: state.apServerIp,
                      apServerHost: state.apServerHost,
                      apServerCountryCode: state.apServerCountryCode,
                      vpnServerId: state.vpnServerId,
                    },
                  },
                },
              })
            }
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};